import Vue from 'vue';
import Vuex from 'vuex';
import Store from '../shared/store';

Vue.use(Vuex);

type PromptResponse = {
  content?: string;
  selections?: Record<string, boolean>;
  isTrue?: boolean;
  updatedAt: number;
};

export const defaultState = {
  promptResponse: {} as Record<string, PromptResponse>,
};

export default new Vuex.Store({
  state: defaultState,
  mutations: {
    setPromptResponse(
      state,
      {
        promptId,
        key,
        value,
      }: { promptId: string; key: string; value: string | Record<string, any> | boolean },
    ) {
      const updatedAt = new Date().getTime();

      Store.namespace(promptId).set(key, value);
      Store.namespace(promptId).set('ts', updatedAt);
      state.promptResponse[promptId] = {
        ...state.promptResponse[promptId],
        [key]: value,
        updatedAt,
      };
    },

    revivePromptResponse(state, { promptId, key }: { promptId: string; key: string }) {
      const revivedValue = Store.namespace(promptId).get(key);
      const revivedValueTimestamp = Store.namespace(promptId).get('ts');
      state.promptResponse[promptId] = {
        ...state.promptResponse[promptId],
        [key]: revivedValue,
        updatedAt: revivedValueTimestamp,
      };
    },

    injectResponseSet(
      state,
      responses: Record<
        string,
        {
          id: string;
          promptId: string;
          verdict?: boolean;
          selection?: string;
          selections?: string[];
          text?: string;
        }
      >,
    ) {
      Object.keys(responses).forEach(uniqueId => {
        let content = responses[uniqueId].text;
        if (content) {
          try {
            content = JSON.parse(content);
          } catch (err) {
            console.error(err);
          }
        }
        state.promptResponse[uniqueId] = {
          ...state.promptResponse[uniqueId],
          content,
          selections: responses[uniqueId].selection
            ? { [responses[uniqueId].selection ?? '']: true }
            : (responses[uniqueId].selections ?? []).reduce((a, b) => {
                a[b] = true;
                return a;
              }, {} as Record<string, boolean>),
          isTrue: responses[uniqueId].verdict,
          updatedAt: new Date().getTime(),
        };
      });
    },
  },
  getters: {
    promptResponse: state => (uniqueId: string, key: keyof PromptResponse) => {
      if (!state.promptResponse[uniqueId]) return undefined;
      return state.promptResponse[uniqueId][key];
    },
  },
});
