


















import { Route } from 'vue-router';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { signOut } from '@/shared/auth';

@Component({})
export default class Header extends Vue {
  homeLink = '/';

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(route: Route) {
    if (route.path.indexOf('/instructor') === 0) {
      this.$set(this, 'homeLink', '/instructor');
    } else if (route.path.indexOf('/admin') === 0) {
      this.$set(this, 'homeLink', '/admin');
    }
  }

  signOut = signOut;
}
