import Vue from 'vue';

import {
  BIcon,
  BIconCheckCircleFill,
  BIconJournalCheck,
  BIconJournals,
  BIconXCircle,
  BIconBug,
  BIconEyeSlashFill,
  BootstrapVue,
} from 'bootstrap-vue';
import '@/main.scss';

Vue.use(BootstrapVue);
Vue.component('BIcon', BIcon);
Vue.component('BIconXCircle', BIconXCircle);
Vue.component('BIconCheckCircleFill', BIconCheckCircleFill);
Vue.component('BIconJournalCheck', BIconJournalCheck);
Vue.component('BIconJournals', BIconJournals);
Vue.component('BIconBug', BIconBug);
Vue.component('BIconEyeSlashFill', BIconEyeSlashFill);
