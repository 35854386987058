







import { Component, Prop, Vue } from 'vue-property-decorator';
import PromptEditor from './Editor.vue';

@Component({
  components: {
    PromptEditor,
  },
})
export default class Longform extends Vue {
  @Prop({ type: String }) question!: string;

  @Prop({ type: String }) id!: string;

  @Prop({ type: Boolean }) readOnly!: boolean;
}
